<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Familia</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="familiaRequest.status"
                        label="Ativar"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Tipo de Produto</label>
                    <v-combobox
                        v-model="familiaRequest.tipoProdutoSelected"
                        :items="listTipoProduto"
                        item-text="descricao"
                        item-value="id"
                        clearable
                        outlined
                    ></v-combobox>
                </v-col>
                <v-col cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Descrição</label>
                    <v-text-field
                        v-model="familiaRequest.descricao" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="familiaRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            familiaRequest: {
                id: 0,
                descricao: ""
            },

            listTipoProduto: [],

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("familiaModule/GetById", this.id);

                    if (response.success) {
                        this.familiaRequest = response.result;
                    }

                }
                else {
                    this.familiaRequest.status = 1;
                }
            },

            async getLists() {
                
                this.listTipoProduto = await this.$store.dispatch("tipoProdutoModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/produto/familiaList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    if (this.familiaRequest.status === null) {
                        this.familiaRequest.status = 0;
                    }

                    this.familiaRequest.id = this.id;
                    this.familiaRequest.idTipoProduto = this.familiaRequest.tipoProdutoSelected.id;

                    const result = await this.$store.dispatch("familiaModule/CreateUpdate", this.familiaRequest);

                    if (result.success === true) {
                        this.showToast("success", "Sucesso!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Aviso!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .colButtonList {
        padding-top: 20px;    
    }

    .colButtonList2 {
        margin-top: 8px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>